import React, { useEffect } from "react";
import nextCookies from "next-cookies";
import cookies from "cookies";
import { connect } from "react-redux";
import { setUser } from "../../state/creators";
import { Props } from "../../_interfaces/page.index.interface";
import { GetUserInformation } from "../../functions/helpers";
import { CheckAuthSession } from "../../functions/http-functions/auth";
import NoAuthLayout from "../../components/layout/noAuthLayout";
import Login from "../../components/account/login";
import LandingIcon from "../../components/icons/landing";

const LoginPage: React.FC<Props> = ({ me, redirect, setUser }) => {
	useEffect(() => {
		if (me.type !== 200) {
			setUser(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<NoAuthLayout>
			<Login redirect={redirect} />
			<LandingIcon />
		</NoAuthLayout>
	);
};

export async function getServerSideProps(ctx) {
	const { token } = await nextCookies(ctx);
	const decodedToken = await GetUserInformation(token);

	let me;

	try {
		me = await CheckAuthSession(token, {
			userId: decodedToken ? decodedToken.userId : "",
		});

		const { res } = ctx;
		res.writeHead(302, { location: "/" });
		res.end();

		return {
			props: {},
		};
	} catch {}

	const Cookies = new cookies(ctx.req, ctx.res);

	Cookies.set("preauthtoken", "", {
		expires: new Date(0),
		httpOnly: true,
		SameSite: "None",
	});

	return {
		props: {
			me: me ?? {},
			redirect: ctx.query.redirect || "",
		},
	};
}

const mapDispatchToProps = {
	setUser,
};

export default connect(null, mapDispatchToProps)(LoginPage);
