import { ReactChild, useState } from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import { ILogin, UserLoginModel } from "../../../_models/data.userLogin.model";
import { ApiResultModel } from "../../../_models/api.result.model";
import { ValidateLogin } from "../../../functions/validators";
import { LoginProxy } from "../../../functions/http-functions/auth";
import Text from "../inputs/text";
import Password from "../inputs/password";
import FormError from "../errors/http";
import { Form } from "../../../styles/forms";
import { ButtonList, SubmitButton } from "../../../styles/type/buttons";
import { Cta } from "../../../styles/type/typography";
import { IHttpResponse } from "../../../functions/http-functions/http";

interface Props {
	redirect?: string;
}

const LoginForm = ({ redirect }: Props) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [state, setState] = useState<ILogin>(new UserLoginModel());
	const [error, setError] = useState<ReactChild>(null);

	const router = useRouter();

	const handleChange = ({ target: { name, value } }) => {
		setState((prevState) => ({ ...prevState, [name]: value }));
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setState((prevState) => ({
			...prevState,
			usernameError: null,
			passwordError: null,
		}));
		const ValidateStep = ValidateLogin({ ...state });
		if (ValidateStep.valid) {
			login();
		} else {
			for (let i = 0, item; !!(item = ValidateStep.errors[i++]); ) {
				setState((prevState) => ({
					...prevState,
					[item.name]: item.error,
				}));
			}
		}
	};

	const login = async () => {
		try {
			setLoading(true);
			setError(null);
			await LoginProxy(new UserLoginModel({ ...state }));

			router.push("/login/validate");
		} catch (err: unknown) {
			const error = err as IHttpResponse;
			const ErrorResult = new ApiResultModel(error);
			setError(<FormError errors={[ErrorResult.message]} />);
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<Form>
				<Text name="username" label="Username" value={state.username} onChange={handleChange} error={state.usernameError} required={true} />
				<Password name="password" label="Password" value={state.password} onChange={handleChange} error={state.passwordError} required={true} />
				{error}
				<ButtonList justify="flex-end">
					<SubmitButton onClick={handleSubmit} full disabled={loading} loading={loading ? "true" : undefined}>
						Log In
					</SubmitButton>
				</ButtonList>
				<ButtonList justify="center" pushTop>
					<Link href="/account/reset-password">
						<Cta center>Forgot Username/ Password</Cta>
					</Link>
				</ButtonList>
				<ButtonList justify="center" pushTop>
					<Link href="/about">
						<Cta center>Return to Home</Cta>
					</Link>
				</ButtonList>
			</Form>
		</>
	);
};

export default LoginForm;
